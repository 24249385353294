const images = [
  {
    "id": 0,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd000.jpg"
  },{
    "id": 1,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd001.jpg"
  },
  {
    "id": 2,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd002.jpg"
  },
  {
    "id": 3,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd003.jpg"
  },
  {
    "id": 4,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd004.jpg"
  },
  {
    "id": 5,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd005.jpg"
  },
  {
    "id": 6,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd006.jpg"
  },
  {
    "id": 7,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd007.jpg"
  },
  {
    "id": 8,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd008.jpg"
  },
  {
    "id": 9,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd009.jpg"
  },
  {
    "id": 10,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd010.jpg"
  },
  {
    "id": 11,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd011.jpg"
  },
  {
    "id": 12,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd012.jpg"
  },
  {
    "id": 13,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd013.jpg"
  },
  {
    "id": 14,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd014.jpg"
  },
  {
    "id": 15,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd015.jpg"
  },
  {
    "id": 16,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd016.jpg"
  },
  {
    "id": 17,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd017.jpg"
  },
  {
    "id": 18,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd018.jpg"
  },
  {
    "id": 19,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd019.jpg"
  },
  {
    "id": 20,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd020.jpg"
  },
  {
    "id": 21,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd021.jpg"
  },
  {
    "id": 22,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd022.jpg"
  },
  {
    "id": 23,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd023.jpg"
  },
  {
    "id": 24,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd024.jpg"
  },
  {
    "id": 25,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd025.jpg"
  },
  {
    "id": 26,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd026.jpg"
  },
  {
    "id": 27,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd027.jpg"
  },
  {
    "id": 28,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd028.jpg"
  },
  {
    "id": 29,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd029.jpg"
  },
  {
    "id": 30,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd030.jpg"
  },
  {
    "id": 31,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd031.jpg"
  },
  {
    "id": 32,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd032.jpg"
  },
  {
    "id": 33,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd033.jpg"
  },
  {
    "id": 34,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd034.jpg"
  },
  {
    "id": 35,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd035.jpg"
  },
  {
    "id": 36,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd036.jpg"
  },
  {
    "id": 37,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd037.jpg"
  },
  {
    "id": 38,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd038.jpg"
  },
  {
    "id": 39,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd039.jpg"
  },
  {
    "id": 40,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd040.jpg"
  },
  {
    "id": 41,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd041.jpg"
  },
  {
    "id": 42,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd042.jpg"
  },
  {
    "id": 43,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd043.jpg"
  },
  {
    "id": 44,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd044.jpg"
  },
  {
    "id": 45,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd045.jpg"
  },
  {
    "id": 46,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd046.jpg"
  },
  {
    "id": 47,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd047.jpg"
  },
  {
    "id": 48,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd048.jpg"
  },
  {
    "id": 49,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd049.jpg"
  },
  {
    "id": 50,
    "author": "",
    "title": "",
    "year": 2020,
    "url": "/fd2020/fd050.jpg"
  },
]

export default images;